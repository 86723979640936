import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import InfoCard from "../../Admin/InfoCard";
import { GetRecordList } from "../../../utils";
import { useNavigate } from "react-router-dom";

let initialCustomerData = [
  {
    title: "Orders",
    value: "14k",
    interval: "Total Order Count",
  },
];

const OrderInfoCard = () => {
  const [customerData, setCustomerData] = useState(initialCustomerData);
  const [searchResult, setSearchResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleListSearch = async () => {
    try {
      setIsLoading(true);
      const data = await GetRecordList({
        objectType: "s_order",
        pageSize: 1,
        offset: 0,
      });
      setCustomerData((prevData) =>
        prevData.map((card) => ({
          ...card,
          value: data?.total_records?.toString() || "N/A",
        }))
      );
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleListSearch();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return customerData.map((card, index) => (
    <Grid key={index} size={{ xs: 12, sm: 12, lg: 12 }}>
      <InfoCard {...card} onClick={() => navigate("/orders")} />
    </Grid>
  ));
};

export default OrderInfoCard;
