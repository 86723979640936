import React from "react";
import { Box, Drawer, Typography, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import {
  AccountStatusCell,
  ActionTypeCell,
  CustomerTypeCell,
  DocumentDateCell,
  PaymentMethodsCell,
  PaymentStatusCell,
  ReceivedByERPCell,
  RowVersionCell,
} from "../Lists/Helpers/ListHelper";

const DetailView = ({ selectedRow, sort = false, title }) => {
  const sortedEntries = sort
    ? Object.entries(selectedRow).sort((a, b) => a[0].localeCompare(b[0]))
    : Object.entries(selectedRow);

    console.log(sortedEntries);
  return (
    <Box sx={{ flexGrow: 1, overflow: "auto", px: 2 }}>
      <Grid container direction="column" spacing={0}>
        {sortedEntries.map(([key, value]) => (
          <Grid
            key={key}
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              py: 1,
            }}
          >
            <Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                variant="caption"
                color="text.secondary"
                fontWeight="bold"
              >
                {key.replace(/_/g, " ").toUpperCase()}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Typography variant="caption">
                {renderCellValue(key, value)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const renderCellValue = (key, value) => {

    const fieldsToExclude = ["epoch","cache","documentdb","sql","collection_menu"];

    if(value == null) return '';

    if (fieldsToExclude.some((word) => key.toLowerCase().includes(word))) {
     return '';
    }

  switch (key) {
    case "received_by_ERP":
      return <ReceivedByERPCell value={value} />;
    case "account_status":
      return <AccountStatusCell value={value} />;
    case "received_by_server":
      return <ReceivedByERPCell value={value} />;
    case "order_date":
      return <DocumentDateCell value={value} />;
    case "payment_method":
      return <PaymentMethodsCell value={value} />;
    case "action_type":
      return <ActionTypeCell value={value} />;
    case "row_version":
    case "row_origin":
      return <RowVersionCell value={value} />;
    case "type":
      return <CustomerTypeCell value={value} />;
    default:
      return String(value);
  }
};


const DetailDrawer = ({ open, onClose, selectedRow, sort = false, title }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: "40%",
          maxWidth: "600px",
          height: "100%",
          zIndex: 10000,
        },
      }}
    >
      {selectedRow && (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "60px",
              p: 2,
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Typography variant="h6">{title}</Typography>
            <IconButton
              onClick={onClose}
              edge="end"
              aria-label="close"
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DetailView selectedRow={selectedRow} sort={sort} />
        </Box>
      )}
    </Drawer>
  );
};

export default DetailDrawer;
